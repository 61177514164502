.navigation {
    width: 250px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    @media screen and (max-width: $breakpoint-sm) {
        flex-direction: row;
        position: relative;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: auto;
        gap: 0;
        padding: 1rem 1rem 0 1rem;
        flex-wrap: wrap;
    }

    &__branding {
        text-decoration: none;
        &::after {
            content: "BETA";
            display: block;
            text-align: right;
            color: var(--color-highlight-3);
            padding: 0.25rem;
            @media screen and (max-width: $breakpoint-sm) {
                display: none;
            }
        }
        &__wrapper {
            border: 2px solid var(--color-very-subtle);
            padding: 0.2rem 0.5rem;
            border-radius: 0.25rem;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;
        }
        &__text {
            color: var(--color-normal);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-family: Arial, Helvetica, sans-serif;
        }
        &__logo {
            width: 2.5rem;
            aspect-ratio: 1;
            border-radius: 100%;
            z-index:1;
            position: relative;
            overflow: hidden;
            &::before {
                transition: 200ms ease-in-out all;
                content: "";
                position: absolute;
                z-index: 0;
                top: 0rem;
                left: 0rem;
                border-radius: 100%;
                right: 0rem;
                bottom: 0rem;
                background-image:
                linear-gradient(225deg, transparent 50%, $color-dark-blue 50%),
                linear-gradient(-45deg,transparent 50%, $color-dark-blue 50%);
            }
        }
        &:hover &__logo::before {
            transform: rotate(360deg);
        }
        &__name {
            font-size: 1.5rem;
 
            font-weight: bold;
        }
        &__claim {
            left: 4rem;
            color: var(--color-subtle);
            white-space: nowrap;
        }
        @media screen and (max-width: $breakpoint-sm) {
            &__wrapper {
                padding: 0.25rem;
            }
            &__name {
                font-size: 0.85rem;
            }
            &__claim {
                font-size: 0.75rem
            }
            &::after {
                font-size: 0.75rem;
            }

        }
    }
    &__claim {
        text-align: center;
        font-size: 1.25rem;
        color: #7f7f7f;
        padding:0 1rem;
        @media screen and (max-width: $breakpoint-sm) {
            order: 7;
            width: 100%;
            padding: 1rem 0 0 0;
            font-size: 1rem;
         }
    }
    &__main-navigation {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        list-style: none;
        padding: 0;
        &__item {
            a {
                color: $color-black;
                background-position: left center;
                background-repeat: no-repeat;
                text-decoration: none;
                padding-left: 2rem;
                font-size: 1.5rem;
                background-size: 1.5rem;
            }
            &--home  a {
                background-image: var(--icon-home);
                body.dark & {
                    filter: invert(100%);
                }
            }
            &--about  a {
                background-image: var(--icon-about);
                body.dark & {
                    filter: invert(100%);
                }
            }
            &--tracking  a {
                background-image: var(--icon-tracking);
                background-size: 1.2rem;
            }
            &--alerts  a {
                background-image: var(--icon-alerts);
            }
        }
        @media screen and (max-width: $breakpoint-sm) {
            flex-direction: row;
            margin: 0;
            gap: 0.75rem;
            flex-wrap: wrap;
            &__item a {
                font-size: 1rem;
                background-size: 1rem;
                padding-left: 1.5rem;
            }
            &__item {
                &--home {
                    display: none;
                }
            }
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        @media screen and (max-width: $breakpoint-sm) {
            gap: 0.5rem;
        }
    }
    &__user-count {
        text-align: center;
        @media screen and (max-width: $breakpoint-sm) {
            font-size: 0.75rem;
        }
        @media screen and (max-width: 400px) {
            display: none;
        }
    }
}