/* fira-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-800 - latin-ext_latin */
  @font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../assets/fonts/fira-sans-v16-latin-ext_latin-800.svg#FiraSans') format('svg'); /* Legacy iOS */
  }

html, body {
    font-family: "Fira Sans", Arial, Helvetica, sans-serif;
}