
.page {
    @media (max-width: 900px) {
        flex-direction: column;
    }
    &__content {
        padding: 0rem 5rem 2rem 2rem;
        flex-grow: 1;
        @media (max-width: 900px) {
            padding: 2rem 1rem;
        }
    }
    h1 {
        text-transform: uppercase;
        margin-bottom: 4rem;
        margin-top: 1rem;
    }
    footer {
        padding: 2rem 0;
        text-align: center;
        .legal-text {
            max-width: 800px;
            margin: 0 auto;
            padding: 0 1rem;
            color: $color-inactive;

        }
        .footer__banner {
            display: none;
        }
        @media (max-width: 900px) {
            .footer__banner {
                display: inline;
            }
        }
        .footer__links {
            display: flex;
            padding: 2rem;
            justify-content: center;
            color: $color-inactive;
            a {
                color: $color-inactive;
                text-decoration: none;
                transition: all 200ms ease-in-out;
                text-transform: uppercase;
                &::before {
                    content: "|";
                    
                    display: inline-block;
                    padding: 0 0.5rem;
                }
            
                &:hover {
                    color: $color-dark-red;
                    &::before {
                        color: $color-inactive;
                    }
                }
            }
            @media (max-width: 640px) {
                flex-direction: column;
                align-items: center;
                a::before {
                display: none;
                }
                a {
                    margin-top: 0.5rem;
                }
            }

        }
    }

    &--tos, &--privacy {
        h1, h2 {
            max-width: 800px;

        }
        p {
            max-width: 800px;
        }
    }
    &--join {
        h1 span {
            color: $color-dark-red;
            vertical-align: super;
            font-size: 1.5rem;
        }
        p {
            font-size: 1.5rem;
            font-weight: bold;
            max-width: 800px;
        }
      
        &__aside {
            padding: 2rem 0 0 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            min-height: 100vh;
            height: 100%;
            color: var(--color-subtle);
            h3 {
                font-weight: normal;
                font-size: 1rem;
                margin: 1rem 0 0 0;
                span {
                    font-weight: bold;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 3rem 0 0 0;
                display: flex;
                flex-direction: column;
                justify-content: center;

                li {
                    margin: 0;
                    display: inline-block;
                    white-space: nowrap;
                }
            }
            &__banner {
                position: absolute;
                bottom: 2rem;
                left: 50%;
                transform: translateX(-50%);
            }
            .logo-wrapper {
                
                    height: 120px;
                    width: 120px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $color-dark-blue;
                    border-radius: 50%;
                
            }
            .getcontext-logo {
                width: 60%;
                height: 60%;
                background:#fff;
                &::after {
                    background: $color-dark-blue;
                }
           
            }
            @media (max-width: 900px) {
                text-align: left;
                height: auto;
                min-height: auto;
                align-items: flex-start;   
                position: relative;      
                padding-bottom: 3rem;    
                overflow: hidden;   
                .page--join__branding {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 2rem;
                }
                ul {
                    width: 100%;
                    position: absolute;
                    left: calc(120px + 2rem);
                    top: 5rem;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    font-size: 0.75rem;
                    gap: 0.25rem;
                }
                &__banner {
                    display: none;
                }
            }

        }
        &__figure {
            display: flex;
            margin: 3rem 0;
            ul {
                list-style: none;
                li {
                    margin: 0.5em 0;
                }
            }
        }
        &__approach {
            margin: 2rem 0;
            padding: 2rem;
            border: 1px solid var(--color-normal);
            font-size: 1.5rem;
            font-weight: bold;
            span {
                font-weight: normal;
            }
            h2 {
                margin-top: 0;
                font-size: 1.5rem;
            }
            li {
                margin-bottom: 1rem;
                &::marker {
                    content: "➔ ";

                }
            }
            &__logos {
                margin: 2rem 0 0 2rem;
                display: flex;
                justify-content: flex-start;
                gap: 2rem;
                img {
                    height: 100px;
                }
            }
        }
        &__life-cycle {
            padding: 13rem 2rem 2rem 2rem;
            flex-grow: 1;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            border: 2px solid $color-inactive;
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-image: url(./getcontext-loop.svg);
                background-repeat: no-repeat;
                background-position: top 1.5rem center;
                background-size: auto 10rem;
            }
            ul {
                margin: 0;
                padding: 0 0 0 1rem;
            }
            &__item {
                &:last-child li:nth-last-child(2) {
                    max-width: 90px;
                }
                &:last-child  li:last-child {
                    position: relative;
                    &::after {
                        display: block;
                        position: absolute;
                        content: "";
                        left: 100px;
                        bottom: -0.4rem;
                        width: 70px;
                        height: 70px;                        
                        background-image: url("../assets/images/trees.png");
                        background-repeat: no-repeat;
                        background-position: bottom 0.5rem right 1rem;
                 
                    }
                }
            }
        }
        &__features {
            padding: 2rem;
            width: 25%;
            border-top: 2px dashed $color-inactive;
            border-right: 2px dashed $color-inactive;
            border-bottom: 2px dashed $color-inactive;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            ul {
                margin: 0;
                padding: 0 0 0 1rem;
            }
            .b-corp-logo {
                width: 6rem;
            }
        }
        @media (max-width: 900px) {
            &__figure {
                flex-direction: column;
                align-items: stretch;
            }
            &__features {
             
                width: auto;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 2rem;
                border-top: none;
                border-left: 2px dashed $color-inactive;
            }
            &__banner {
                display: none;
            }
        }
        @media (max-width: 640px) {
            h3 {
                font-size: 1rem;
            }
            &__life-cycle {
                padding: 14rem 1rem 1rem 1rem;
                &::after {
                    background-position: top 2rem center;
                    background-size: auto 10rem;
                }
                flex-direction: column;
                align-items: center;
                &__item {
                    width: 300px;
                }
            }
            &__features {
                padding: 1rem;
            }
        }
    }
}

.getcontext-logo {
    border-radius: 100%;
    background: black;
    position: relative;
    z-index:0;
    &::after {
        content: " ";
        display: block;
        width: 50%;
        height: 50%;
        border-top-right-radius: 100%;
        transform: rotate(45deg);
        transform-origin: bottom left;
        top: 0;
        right: -1px;
        position: absolute;
        background: white;
        z-index: 1;
    }
}

.sidebar {
    padding: 2rem;
    background-color: var(--color-very-subtle);
    @media (max-width: 900px) {
     padding: 1rem;
    }
}