@import-normalize;
@import "fonts.scss";
@import "constants.scss";
@import "pages.scss";

html {
  overflow-y: scroll;
}

body,
html {
  text-align: left;
  margin: 0;
  padding: 0;
  position: relative;
}
* {
  box-sizing: border-box;
}
.header {
  background: var(--color-normal);
  color: var(--color-background);
  padding: 0 2rem;
  position: fixed;
  height: 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__buttons {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }
  .join-button {
    color: $color-white;
    background-color: $color-dark-blue;
    border-radius: 0.25rem;
    display: inline-block;
    padding: 1px 3rem;
    transition: all 200ms ease-in-out;
    @media (max-width: 640px) {
      padding: 1px 2rem;
    }
    &:hover {
      background-color: lighten($color-dark-blue, 10%);
      transform: scale(1.1);
    }
  }
  a {
    color: var(--color-background);
    text-decoration: none;
  }
}



.color-scheme-button {
  border: 0;
  outline: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  &--light {
    background-image: url("../assets/images/light-mode.svg");
    filter: invert(100%);

  }
  &--dark {
    background-image: url("../assets/images/dark-mode.svg");

  }
}

.domain-switch {
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 0.25rem
  }
  &__item {
    a {
      border-radius: 2px;
      padding: 0.35rem 1rem;
      background: var(--color-very-subtle);
      color: var(--color-subtle);
      text-decoration: none;
      display: inline-block;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $color-dark-red;
        color: var(--color-background)
      }
      &.active {
        background-color: var(--color-highlight-3);
        color: var(--color-background);
      }
  }
}
  
}
.search-navigation {
  grid-area: 1 / 2 / auto / 2;
  margin: 0 0 1rem 0;
  &__buttons {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 0.5rem 4rem;
    @media (max-width:1000px) {
      flex-wrap: wrap;
      .hot-topics {
        margin: 0;
        flex-direction: row;
        width: 100%;
        align-items:center ;
        gap: 1rem;
        .tag-list {
          flex-grow: 1;
          justify-content: flex-start;
        }
      }
    }
  }
}
.main-content {
  display: flex;

  @media screen and (max-width: 480px) {
    width: auto;
  }
}

.hot-topics {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  align-items: flex-end;
  &__label {
    display: inline-block;
  }
  .tag-list {
    background: var(--color-very-subtle);
    padding: 0.25rem;
    border-radius: 0.25rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
  h3 {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: normal;
    display: inline-block;
    margin: 0.25rem 0;
    padding: 0;
    
    color: var(--color-subtle);
  }
  margin: 0 0 1rem 0 ;

}



.sidebar {
  background-color: var(--color-very-subtle);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  margin: 0;
  padding: 0;
  &__heading {
    margin: 0;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    padding: 0.82rem 1.5rem;
    background-color: var(--color-highlight-3);
    color: $color-white;
  }
  @media screen and (max-width: $breakpoint-sm) {
    border-radius: 0;
    &__heading {
      font-size: 1rem;
      border-radius: 0;
      padding: 0.5rem 1rem;
    }
  }
}

.context-list {
  padding: 1.5rem;
  background: var(--color-very-subtle);
  h3 {
    font-weight: bold;
    color: var(--color-normal);
    font-size: 1rem;
      span {
        display: inline-block;
        padding-left: 0.5rem;
        font-weight: normal;
        &::before {
          display: inline;
          content: "(";
        }
        &::after {
          display: inline;
          content: ")";
        }
      }
  }
  h3:first-of-type {
    margin-top: 0;
  }
  &__trending, &__ecosystem {
    border-bottom: 3px solid var(--color-background);
    padding: 0 0 1.5rem 0;
    margin: 0 0 1.5rem 0;
  }
  &__ecosystem {
    h3 {
      
      &::before {
        content: "*";
        display: inline-block;
      }
    }
  }
}
.claim {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: var(--color-subtle);
  margin: 0;
  gap: 2rem;
}

.search-input {
  width: 100%;
  margin: 0rem 0 0.5rem 0;
  position: relative;
  &__input {
    width:100%;
    padding: 0.25rem 3rem 0.25rem 3rem;
    border-radius: 0.25rem;
    font-size: 2rem;
    color: var(--color-normal);
    position: relative;
    &__icon{
      position: absolute;
      left: 10px;
      bottom: 50%;
      transform: translate(0, 50%);
      width: 1.75rem;
      height: 1.75rem;
      overflow: hidden;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 60%;
        border: 2px solid var(--color-subtle);
        content: '';
        border-radius: 50%;
        background-color: var(--color-very-subtle);
        z-index: 10;
      }
      &::after {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 2rem;
        height: 2px;
        background: var(--color-subtle);
        content: '';
        transform: rotate(45deg);
        transform-origin: 0 0%;
        z-index: 9;
      }
    }
    ::placeholder {
      color: var(--color-very-subtle);
    }
    &:focus {
      outline: none;
      box-shadow: none!important;
    }
    border: 2px solid #7f7f7f;
    background-color: var(--color-very-subtle);

  }
  .loading-spinner {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translate(0, 50%);
    width: 2.25rem;
    height: 2.25rem;
  }
  &__clear {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translate(0, 50%);
    font-size: 2rem;
    line-height: 1;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    background: 0;
    outline: 0;
    border: 0;
    cursor: pointer;
    color: var(--color-subtle);
  }
}

.search-filters {
  display: flex;
  margin: 1.5rem 0 1rem 0;
  gap: 2rem;
  width: 100%;
  &__trending, &__type {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 0.25rem;
    li {
      padding: 0;
      margin: 0;
      a {
        color: var(--color-background);
        padding: 0.25rem 0.75rem;
        font-size: 0.85rem;
        text-decoration: none;
        border-radius: 0.25rem;
        display: inline-block;
        transition: all ease-in-out 0.5s;
      }
    }
  }
  &__trending {
    li {
      a {
        background: rgb(214, 211, 211);
        &.active {
          background: var(--color-highlight-1);
          color: white;
          
        }
        &:hover {
          background: darken($color-dark-green, 10%);
          color: white;
        }
      }
    }
  }
  &__type {
    li {
      a {
        background: rgb(214, 211, 211);
        white-space: no;
        &.active {
          background: var(--color-highlight-2);
          color: white;
          &::after {
            content: '⨯';
            display: inline-block;
            padding: 0 0 0 0.35rem;
            transition: all ease-in-out 300ms;
          }
        }
        &:hover {
          background: darken($color-dark-blue, 10%);
          color: white;
          &.active::after {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.item {
  margin: 0 0 1rem 0;
  position: relative;
  z-index: 0;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: var(--color-normal);
    pointer-events: none;
    transition: all 300ms ease-in-out;
    .tag-list a {
      pointer-events: all;
    }
    &--youtube {
      pointer-events: all;
    }
  }

  &>a {
    pointer-events: all;
    text-indent: -1000px;
    color: transparent;
    position: absolute;
    top: -0.75rem;
    left: -1rem;
    right: -1rem;
    bottom: -0.75rem;
    z-index: -1;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: 0.3s;
    &:hover {
      background-color: var(--color-very-subtle);
      transition: 0.3s;

    }
    &:active, &:active ~ div{
      transform: scale(1.05);
    }
  }
  &__image {
    width: 100px;
    max-height: 100px;
    object-fit: cover;
    height: auto;
    border-radius: 0.5rem;
    margin: 1rem 0 1rem 1rem;
    transition: 0.3s;


  }
  &__info {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
      font-size: 0.85rem;
      display: inline-block;
      color: var(--color-subtle);
      margin-right: 0.25rem;
      &::after {
        content: "|";
        display: inline-block;
        margin-left: 0.25rem;
      }
      &:last-child::after {
        content: "";
      }
    }
  }
  &__title {
    padding: 0;
    margin: 0.25rem 0;
    max-width: 600px;
    font-size: 1.25rem;
  }
  &__description {
    font-size: 0.9rem;
    padding: 0;
    margin: 0;
    margin: 0.5rem 0;

  }
  &__video {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}

.tag-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  gap: 0.25rem;
  flex-wrap: wrap;
}

.tag {
  display: inline-block;
  white-space: nowrap;

  a {
    display: inline-block;
    font-size: 0.9rem;
    border-radius: 3px;
    padding: 0.18rem 0.3rem 0.02rem 0.3rem;
    color: var(--color-normal);
    text-decoration: none;
    transition: ease-in-out 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
    background-color: var(--color-tag);
  }
  &--unknown {
    display: none;
  }

  &--time a {
    background-color: var(--color-time);
  }
  &--source a {
    background-color: var(--color-source);
  }
  &--founder a {
    background-color: var(--color-founders);
  }
  &--tag a {
    background-color: var(--color-tag);
  }
  &--advocate a {
    background-color: var(--color-advocate);
  }
  &--blockchain a {
    background-color: var(--color-blockchain);
  }
  &--individual a {
    background-color: var(--color-people);

  }
  &--company a {
    background-color: var(--color-org);

  }
  &--unknown, &--generic a {
    background: #d8d8d8;
  }
}

.legend {
  padding: 0;
  margin: 1rem 0 0 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  &__item {
    font-size: 0.8rem;
    padding: 0 0.5rem 0.5rem 0;
    margin: 0;
    min-width: 33%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    &:before {
      display: block;
      content: " ";
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid var(--color-subtle);
      background-color: $color-blue;
    }
    &--founders:before {
      background-color: var(--color-founders);
    }
    &--advocates:before {
      background-color: var(--color-advocate);
    }
    &--blockchains:before {
      background-color: var(--color-blockchain);
    }
    &--source:before {
      background-color: var(--color-source);
    }
    &--time:before {
      background-color: var(--color-time);
    }
    &--tags:before {
      background-color: var(--color-tag);
    }
  }
}

.individuals-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.individual {
  width: 50%;
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0.25rem solid transparent;
  a {
    background: var(--color-background);
    border-radius: 0.25rem;
    text-decoration: none;
    color: var(--color-normal);
    display: flex;
    transition: ease-in 100ms;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    &:hover {
      background-color: var(--color-less-subtle);
      color: var(--color-background);
      img {
        transform: scale(1.1);
      }
    }
  }


  &__info {
    &__name {
      font-weight: bold;
    }
  }
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    transition: 200ms all ease-in-out;
  }
  &__info {
    margin: 0.5rem;
    p {
      margin: 0;
      padding: 0;
    }
  }
}

.partners {
  margin: 0 1.5rem;
  flex-grow: 1;
  font-size: 0.8rem;
  min-width: 20vw;
  width: 22vw;
  max-width: 25vw;
  position: sticky;
  align-self: flex-start;
  top: 4.5rem;
}

.partner {
  margin: 0 0 2rem 0;
  a {
    text-decoration: none;
    color: var(--color-subtle);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
    transition: all 200ms ease-in-out;
    &:hover {
      color: var(--color-normal);
    }
  }
  &__type {
    display: inline-block;
    border: 1px solid var(--color-subtle);
    border-radius: 0.25rem;
    padding: 0.1rem 0.3rem;
    margin-right: 0.2rem;
  }
  &__source {
    &:after {
      content: " | ";
    }
  }
  &__label {
    display: inline;
  }
  img {
    width: 7rem;
    border-radius: 0.25rem;
    margin-left: 0.75rem;
  }
}

.result-count {
  margin: 0.5rem 0;
  font-size: 0.85rem;
  color: $color-dark-red;
}

@media(max-width: $breakpoint-sm) {
  .results-list {
    margin: 0 1rem;
  }
}

@media (max-width: $breakpoint-md) {
  .individual {width: 100%;}
  .partners {
    margin: 0 1.5rem;
    flex-grow: 1;
    font-size: 0.7rem;
    img {
      width: 3rem;
    }
  }
  
  .header {
    padding-left: 1rem;
    padding-right: 1rem;

  }
  .legend__item {
    min-width: 50%;
  }
}

@media (max-width: $breakpoint-sm) {
  .whats-coming {
    display: none;
  }
  .main-content {
    flex-direction: column;
    padding: 0;
  }
  .context-list {
    position:relative;
    order: -2;
    width: 100%;
    padding:1rem;

  }
  .results-list {
    order: -1;
    max-width: 100%;
    .partner {
      display: block;
      margin: 2rem 0;
      img {
        margin: 0 0 0 1rem;
      }
    }
  }
  .individual {
    width: 50%;
    a {
      height: 40px;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  .search-navigation {
    overflow: hidden;
    margin: 0 1rem;
  }
  .search-input__input {
    width: 100%;
  }
  .domain-switch {
    ul {
    }
    &__item {
      font-size: 1rem;
    }
  }

}

@media (max-width: $breakpoint-xs) {
  .individual {
    width: 100%;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid rgb(50, 50, 50);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(50, 50, 50) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ecosystem-navigation {
  margin: 1.5rem auto 3rem auto;
  width: 40%;
  min-width: 160px;
  aspect-ratio: 1;
  position:relative;
  --rotation: 0deg;
  transition: all 500ms ease-in-out;
  &::before, &::after {
    aspect-ratio: 1;
    width: 100%;
    content: "";
    display: block;
    border-radius: 50%;
    border: 1px solid #bfbfbf;
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    width: calc(50% + 28px);
  }
  &--people {
    transform: rotate(45deg);
    --rotation: 45deg;
  }
  &--projects {
    transform: rotate(135deg);
    --rotation: 135deg;
  }
  &--sources {
    transform: rotate(225deg);
    --rotation: 225deg;
  }
  &--orgs {
    transform: rotate(315deg);
    --rotation: 315deg;
  }
  &__name {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 72px;
    color: var(--color-background);
    background-color: var(--color-highlight-3);
    transform: translate(-50%, -50%) rotate(calc(var(--rotation) * -1));
    transition: all 500ms ease-in-out;
    transform-origin: 50% 50%;
    border-radius: 50%;
    aspect-ratio: 1;
    display: flex; 
    align-items: center; 
    justify-content: center;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
  }
  &__item {
    $this: ".ecosystem-navigation__item";
    position: absolute;
    font-size: 0.8em;
    transform: translate(-50%, -50%) rotate(calc(var(--rotation) * -1));
    border-radius: 50%;
    aspect-ratio: 1;
    width: 64x;
    height: 64px;
    display: flex; 
    align-items: center; 
    justify-content: center;
    z-index: 1;
    transition: all ease-in-out 500ms;
    &:hover {
      cursor: pointer;
      border-width: 3px;

    }
    &--people {
      top: calc(50% - calc(50% / 1.41));
      left: calc(50% - calc(50% / 1.41));
      background-color: var(--color-people);
    }
    &--orgs{
      top: calc(50% - calc(50% / 1.41));
      left: calc(50% + calc(50% / 1.41));
      background-color: var(--color-org);
    }
    &--projects {
      top: calc(50% + calc(50% / 1.41));
      left: calc(50% - calc(50% / 1.41));
      background-color: var(--color-project);
    }
    &--sources {
      top: calc(50% + calc(50% / 1.41));
      left: calc(50% + calc(50% / 1.41));
      background-color: var(--color-source);
    }
    &--active {
      border: 4px solid #000;
      font-weight: bold;
    }
    &--active#{&}--people {
      border-color: darken($color-green, 30%);
    }
    &--active#{&}--orgs {
      border-color: darken($color-red, 30%);
    }
    &--active#{&}--projects {
      border-color: darken($color-blue, 30%);
    }
    &--active#{&}--sources {
      border-color: darken($color-yellow, 40%);
    }
    &--disabled {
      background-color: var(--color-very-subtle);
      color: #7f7f7f;
      border: 1px solid #7f7f7f;
      cursor: default
    }

  }
  @media (max-width: $breakpoint-sm) {
    width: 100%;
    aspect-ratio: inherit;
    display: flex;
    gap: 1rem;
    --rotation: 0deg;
    transform: rotate(0deg);
    margin: 0 0 1.5rem 0;
    &__item {
      position: static;
      transform: translate(0,0) rotate(0deg);
    }
    &::before, &::after {
      display: none;
    }
    &__name {
      display: none;
      transform: rotate(0deg)
    }
  }


}

@import "app.scss";
@import "navigation.scss";
