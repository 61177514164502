.app {
    display: grid;
    max-width: 1680px;
    margin: 0 auto;
    padding: 1rem;
    grid-template-columns: 250px 1fr 28vw;
    grid-template-rows: min-content 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 0px;
    .navigation {
        grid-area: 1 / 1 /3 /1;
    }
    .sidebar {
        grid-area: 1 / 3 / 3 / 3;

      }
    .page {
        grid-area: 1 / 2 / 3 / 2 ;

    }
    .results-list {
        grid-area: 2 / 2 / auto / 2;
        max-width: 100%;
        overflow:hidden;
      
      }
    @media screen and (max-width: $breakpoint-md) { 
        gap: 1rem;
        padding: 0;
    }
    @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        .navigation {
            position: relative;
            grid-area:  1/1/2/1;
        }
        .search-navigation {
            grid-area: 2/1/2/1;
        }
        .sidebar {
            grid-area: 3/1/3/1;
        }
        .results-list {
            grid-area: 4/1/4/1;
        }
        .page {
            grid-area: 4 / 1 / 4 /1 ;
        }
    }

}
