$color-black: #000;
$color-white: #fff;
$color-background: #ddd;
$color-inactive: #666;
$color-dark-gray: #2c2c2c;
$color-light-gray: #f2f2f2;


$color-green: #c3d69b;
$color-orange: #fac090;
$color-brown: #c4bd97;
$color-blue: #8eb4e3;
$color-red: #d99694;
$color-dark-red: #990000;
$color-light-red: #c00000;
$color-turquoise: #93cddd;
$color-yellow: rgb(235, 233, 158);
$color-dark-blue: #0070c0;
$color-dark-green: #00b050;

$breakpoint-md: 1280px;
$breakpoint-sm: 800px;
$breakpoint-xs: 400px;

:root {
    --color-normal: #{$color-black};
    --color-background: #{$color-white};
    --color-subtle: #{$color-inactive};
    --color-very-subtle:#{$color-light-gray};
    --color-less-subtle:#{$color-dark-gray};
    --color-tag: #{$color-blue};
    --color-founders: #{$color-brown};
    --color-advocate: #{$color-red};
    --color-blockchain: #{$color-turquoise};
    --color-source: #{$color-orange};
    --color-org: #{$color-red};
    --color-project: #{$color-blue};
    --color-people: #{$color-brown};
    --color-time: #{$color-green};
    --color-highlight-1: #{$color-dark-green};
    --color-highlight-2: #{$color-dark-blue};
    --color-highlight-3: #{$color-light-red};
    body {
        transition: background 800ms ease-in-out;
    }
    body.dark {
        --color-normal: #{$color-white};
        --color-background: #{$color-black};
        --color-subtle: #{$color-inactive};
        --color-very-subtle: #{$color-dark-gray};
        --color-tag: #{darken($color-blue, 20%)};
        --color-founders: #{darken($color-brown, 20%)};
        --color-advocate: #{darken($color-red, 20%)};
        --color-blockchain: #{darken($color-turquoise, 20%)};
        --color-source: #{darken($color-orange, 20%)};
        --color-time: #{darken($color-green, 20%)};
        .page--join__life-cycle::after {
            filter: invert(100%);
        }
    }
}

body {
    color: var(--color-normal);
    background-color: var(--color-background);
}

:root {
    --icon-facebook: url('data-url:../../../node_modules/@fortawesome/fontawesome-free/svgs/brands/facebook-f.svg');
    --icon-twitter: url('data-url:../../../node_modules/@fortawesome/fontawesome-free/svgs/brands/twitter.svg');
    --icon-instagram: url('data-url:../../../node_modules/@fortawesome/fontawesome-free/svgs/brands/instagram.svg');
    --icon-email: url('../../node_modules/@fortawesome/fontawesome-free/svgs/regular/envelope.svg');
    --icon-home: url('../../node_modules/@fortawesome/fontawesome-free/svgs/solid/house.svg');
    --icon-tracking: url('../../node_modules/@fortawesome/fontawesome-free/svgs/solid/bookmark.svg');
    --icon-about: url('../../node_modules/@fortawesome/fontawesome-free/svgs/solid/circle-info.svg');
    --icon-alerts: url('../../node_modules/@fortawesome/fontawesome-free/svgs/regular/bell.svg');
}